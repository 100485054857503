// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body{
    color: white;
}

.menutext{
    font: Bold 12px/25px Arimo;
    letter-spacing: 3.12px;
    color: #FFFFFF;
    opacity: 1;
}
#basic-brand-header{
    z-index: 1;
}
#feature, #cta-interior{
    
    overflow: hidden;
}
#feature{
    background: #000;
}
#cta-interior{
    background: $primary-3;
}
.bg-img-black{
    background-color: #000;
}
.bg-img {
    overflow: hidden;
    &:before{
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.55;
        background-repeat: no-repeat;
        background-position: 50% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }

    
}
[data-link="campaign"]:before{
    background-image: url(/assets/img/london-street-level.png);
}
.btn-sua{
    border-radius: 4px;
    background: #002C69 0% 0% no-repeat padding-box;
    font: Bold 12px/25px Arimo;
    letter-spacing: 3.12px;
    color: #FFFFFF;
    opacity: 1;
    max-width: 200px;
    max-height: 53px;
    width: 100%;
    text-transform: uppercase;
}
.btn-sua-clear{
    border-radius: 4px;
    border-color: #FFF;
    background: rgba(0,0,0,0) 0% 0% no-repeat padding-box;
    font: Bold 12px/25px Arimo;
    letter-spacing: 3.12px;
    color: #FFFFFF;
    opacity: 1;
    max-width: 200px;
    max-height: 53px;
    width: 100%;
    text-transform: uppercase;
}
h1{
    font: 60px/60px 'DM Serif Text';
    letter-spacing: -0.36px;
    color: #FFFFFF;
    opacity: 1;
}
h2{
    font: 33px/40px 'DM Serif Text';
    letter-spacing: -0.07px;
}
h3{
    font: 48px/54px 'DM Serif Text';
    letter-spacing: -0.29px;
}
p{
    font: 17px/25px Arimo;
    letter-spacing: 0.05px;
}
p > strong {
    &.p-emphasis{
        font: 600 20px/29px Arimo;
        letter-spacing: 0.06px;
    } 
    font-weight: 600;

}
.card-img-overlay{
    padding: 2.75rem;
}

.card-hov:hover{
    transition: background-color .2s;
    background-color: $primary!important;
}
.my-accordion-panel-title>p{
    font-size: 10px;
    line-height: 25px;
    font-weight: bold;
    letter-spacing: 2.6px;
}
.my-accordion-panel-title>h3:after {
    content: '+';
    color: $primary;
    float: right;
    background-color: #fff;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center
}

.my-accordion-panel-title:not(.collapsed)>h3:after {
    content: '-';
}

@media (max-width: 1150px){
    .spotlight-head{
        font-size: 4vw;

        
    }
    .my-accordion-panel-title>h3:after {
        width: 5vw;
        height: 5vw;
        line-height: 5vw;
        font-size: 4vw;
    }
    .my-accordion-panel-title>h3 {
        line-height: 5vw
    }

}

@media (max-width: 575.9px){
    .my-accordion-panel-title>h3 {
        margin-right: 3rem;
    }
    .my-accordion-panel-title>h3:after {
        font-size: 7vw;
        line-height: 7vw;
        height: 7vw;
        width: 7vw;
    }
    .spotlight-head {
        font-size: 7vw;
        line-height: 7vw;
    }
}
.spotlight{
    background: rgba(255, 255, 0, 0.3);
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    border-top: 3px solid #f4f6fa;
}
.spotlight:last-of-type{
    margin-bottom: -6rem;
}